
/* --------------------------------------------------
 * Basic HTML Tag Styles
 * --------------------------------------------------*/

body {
  padding-top: 3rem;
  background-color: #EDEEF0;
  width: 100%;
}

/* --------------------------------------------------
 * Basic CIS Layout (Box Model) Styles
 * --------------------------------------------------*/

/*
 * the general style attributes for the cis component box model
 */
.cis-component-box {
  background: #fff;
  padding: 1rem;
  margin: 1rem;
}

.component-main{
  background: #fff;
}

.component-sub{
  background: #fff;
  padding: 1rem;
  margin: 1rem;
}

input[name=ci-search] {
  width: 250px;
}

.intercultural-experience-modal-header{
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 0outlorem;
}

.intercultural-experience-modal-body{
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.intercultural-experience-modal-footer{
  text-align: center;
  margin-top: 0rem;
  margin-bottom: 1rem;
}

.navigation-background{
 /* background-color: #0A1529*/
 /* background-color:rgb(36, 50, 73); */
 background-color: rgb(26, 43, 63);
}

.navigation-button{
  margin-left: 1rem;
}

.intercultural-experience-card{
  background: #fff;
  padding: 1rem;
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  margin-bottom: 1rem;
}

/*.intercultural-experience-card-segment-gap{
  /*margin-top: 1rem;*/ /*
/*}*/

.intercultural-experience-story-author{
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  color: #A5A6A7;
  text-align: right;
}

.intercultural-experience-story-date{
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  color: #A5A6A7;
  text-align: right;
}

.intercultural-expericence-title{
  font-family: 'Lato', sans-serif;
  font-size: 24px;
  font-weight: normal;
}

.intercultural-experience-story-text{
  font-family: 'Ovo', serif;
  font-size: 18px;
  font-weight: normal;
}

.intercultural-experience-story-hashtags{
  font-family: 'Open Sans', sans-serif;
  color: #1DA1F2;
  text-align: left;
}

.intercultural-experience-story-read-more-link{
  font-family: 'Open Sans', sans-serif;
  color: #1DA1F2;
  text-align: right;
  font-size: 16px;
}

.intercultural-experience-detail-link-left{
  font-family: 'Open Sans', sans-serif;
  text-align: left;
}

.intercultural-experience-detail-link-right{
  font-family: 'Open Sans', sans-serif;
  text-align: right;
}
.intercultural-experience-detail-link-right1{
  font-family: 'Open Sans', sans-serif;
  text-align: right;
}

.action-btn{
  width: 10px;
}

.intercultural-experience-detail-link-right1 button{
  width: 50px;
}

.intercultural-experience-detail-link-left button{
  width: 50px;
}

.intercultural-experience-detail-table{
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
}

.menuUl {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.menuUl li a {
  display: block;
  color: #000;
  padding: 8px 16px;
  text-decoration: none;
}

/* Change the link color on hover */
.active1 {
  background-color: #f1f1f1;
  color: #000;
}

/* Change the link color on hover */
.menuUl li a:hover {
  background-color: #f1f1f1;
  color: #000;
}

.nav-item a{
  /* color: #FE4020 !important; */
  border-width: 5;
  border-bottom-color: rgb(26, 43, 63) !important;
  border-bottom-style: solid;
}

.nav-item a:hover{
  /* color: #FE4020 !important; */
  color:  #fff !important;
  border-width: 5;
  border-bottom-color: rgb(188, 155, 92) !important;
  border-bottom-style: solid;
}

.debug-border{
  border: 1px red;
  border-style: solid;
  padding: 1rem;
  margin:1rem;
}

.debug-placeholder{
  color: rgb(225, 0, 0);
}

.modal-delete-btn{
  width: 150px;
}

.alert-notification {
  position:fixed; 
  bottom: 1rem; 
  left: 1rem; 
  width: 33%;
  z-index:9999; 
}

.tabContent{
  border-bottom: #dee2e6 1px solid;
  border-left: #dee2e6 1px solid;
  border-right: #dee2e6 1px solid;
  padding: 1rem;
}

.dropzone-custom{
  width: 100%;
  padding-top: 1rem;
  padding-left: 1rem;
  border: #dee2e6 dashed 2px;
  margin-bottom: 1rem;
}
.tab-pointer{
  cursor: pointer;
}

.iga{
  width: 100%;
}

div.ReactTags__tags {
  position: relative;
}

div.ReactTags__tagInput {
  width: 200px;
  border-radius: 2px;
  display: inline-block;
}
div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 31px;
  margin: 0;
  margin-top: 5px;
  width: 100%;
  border: 1px solid #eee;
}

div.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #ddd;
  background: #eee;
  display: inline-block;
  padding: 5px;
  margin: 0 5px;
  margin-bottom: 5px;
  cursor: move;
  border-radius: 2px;
}
div.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer;
}

div.ReactTags__suggestions {
  position: absolute;
}

div.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: .05em .01em .5em rgba(0,0,0,.2);
  background: white;
  width: 200px;
}

div.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}
div.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}
div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}

.aboutContainer{
  padding: 2rem;
}

.headerContainer {
  background-color:rgb(36, 50, 73);
  color: #ddd;
  padding-top: 2rem;
  padding-bottom: 1rem;
}

.copyrightContainer {
  padding: 1rem;
  background-color:#0A1529;
  color: #ddd;
  margin-left: 0px;
}

.topGap{
  margin-top: 3rem;
}

.polaroid-images a
{
  background: white;
  color: white;
	display: inline;
	float: left;
	margin: 0 15px 30px;
	padding: 10px 10px 25px;
	text-align: center;
	text-decoration: none;
	-webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
	-moz-box-shadow: 0 4px 6px rgba(0,0,0,.3);
	box-shadow: 0 4px 6px rgba(0,0,0,.3);
	-webkit-transition: all .15s linear;
	-moz-transition: all .15s linear;
	transition: all .15s linear;
	z-index:0;
        position:relative;
}

.polaroid-images a:after {
  color: white;
	font-size: 20px;
	content: attr(title);
	position: relative;
  top:15px;
}

.polaroid-images img { 
	display: block; 
  width: inherit; 
}

.polaroid-images a:nth-child(1n)
{
	-webkit-transform: rotate(4deg);  
	-moz-transform: rotate(4deg); 
	transform: rotate(4deg); 
}

.polaroid-images a:nth-child(2n)
{
	-webkit-transform: rotate(4deg);  
	-moz-transform: rotate(4deg); 
	transform: rotate(4deg); 
}
.polaroid-images a:nth-child(3n) { 
	-webkit-transform: rotate(-24deg);  
	-moz-transform: rotate(-24deg); 
	transform: rotate(-24deg); 
}
.polaroid-images a:nth-child(4n)
{
	-webkit-transform: rotate(14deg);  
	-moz-transform: rotate(14deg); 
	transform: rotate(14deg); 
}
.polaroid-images a:nth-child(5n)
{
	-webkit-transform: rotate(-18deg);  
	-moz-transform: rotate(-18deg); 
	transform: rotate(-18deg); 
}

/*
.polaroid-images a:hover{
	-webkit-transform: rotate(0deg); 
	-moz-transform: rotate(0deg);
        transform: rotate(0deg);
	-webkit-transform: scale(1.2); 
	-moz-transform: scale(1.2);
        transform: scale(1.2);
	z-index:10;
	-webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, .7);
	-moz-box-shadow: 0 10px 20px rgba(0,0,0,.7);
        box-shadow: 0 10px 20px rgba(0,0,0,.7);
}
*/

.modalHeaderBg{
  background-color: rgb(247, 247, 247)
}

.modalFooterBg{
  background-color: rgb(247, 247, 247)
}

.btn-gap{
  margin-bottom: 1rem;
}

.cardGap{
  margin-bottom: 1rem;
}

.sidenav {
  height: 100%; /* Full-height: remove this if you want "auto" height */
  width: 160px; /* Set the width of the sidebar */
  position: fixed; /* Fixed Sidebar (stay in place on scroll) */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  right: 0;
  background-color: #111; /* Black */
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 20px;
}

.defContainer{
  margin-top: 1.5rem;
  font-size: 15pt;
  text-align: left;
}

.contentContainer{
    /*padding-bottom:60px;*/   /* Height of the footer */
    /*min-height:100%;*/
    min-height: 100%;
    position:relative;
}

.blockScrolling{
  width: auto;
  overflow: hidden !important;
}

.captureActions{
  padding-right: 1rem;
}

.landingGlobal{
  width: 100%;
 }
  /* TODO: refactor -> remove the whole class */
/*
.left-right-space{
  padding-left: 3rem;
  padding-right: 3rem;
}
*/

.slider-header{
  color: rgb(26, 43, 63);;
}

.slider-line{
  color: rgb(188, 155, 92);
  font-weight: bold;
}

.fullWidth{
  width:100% !important;
}

.cis-header{
  color: rgb(26, 43, 63);
}

.MyOverRideClass{

}

.MyOverRideTransitionedClass{
  
}

.gap-right{
  margin-right: 3rem;
}

.bottomLine{
  vertical-align: bottom;
}

.cisAppMainContainer{
  /*padding: 1rem;*/
}

.cisAppBody{
  padding: 1.5rem;
}

.cisComponent{
  /* padding: 1rem; */
}

.cisComponentHeader {
  padding: 0.5rem;
  /* background-color: rgb(0, 26, 84); */
  background-color: rgb(26, 43, 63);
  color: #ddd;
  border-bottom: rgb(188, 155, 92);
  border-bottom-style: solid;
  border-width: 5px;
  text-align: center;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.cisParagraphHeader{
  color: rgb(26, 43, 63);
  border-bottom: rgb(188, 155, 92);
  border-bottom-style: solid;
  border-width: 2px;
}

.cisParagraphBody{
  padding-top: 1rem;
  /* text-align: justify; */
}

.cisComponentHeader h1{
  font-weight:normal;
}

.cisComponentBody {
  padding: 1rem;
  background-color: #fff;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.cis-contact{
  padding: 1rem;
  text-align: left;
  background-color: rgb(26, 43, 63);
  color: #ddd;
  font-size: 10pt;
  border-top: rgb(188, 155, 92);
  border-top-style: solid;
  border-width: 5px;
}

.cis-contact a{
  color: #ddd;
}